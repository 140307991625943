import VueScript2 from 'vue-script2'
import apiClient from '../../apis/apiClient'
import { dangerToast, successToast } from '../../helpers/toasts'

const STRIPE_SCRIPT_URL = 'https://js.stripe.com/v3/'
export const namespaced = true
export const state = {
    //vue instance
    vue: null,

    //existing cards
    cards: [],

    //new card
    card: null,
    intent: null,
    intent_response: null,
    is_loading: false,

    //used for subscribing after adding a new card
    current_card: null,

    //stripe library
    stripe: undefined,
    cardError: '',
    show_card_form: false,
    paymentMethodComplete: false,
}

const loadStripe = ({ state, commit }) => {
    return new Promise((resolve, reject) => {
        if (state.stripe) {
            resolve(state.stripe)
        } else {
            VueScript2.load(STRIPE_SCRIPT_URL)
                .then(() => {
                    const stripeInstance = Stripe(window.stripe_public_key)
                    commit('SET_VALUE', { stripe: stripeInstance })
                    resolve(stripeInstance)
                })
                .catch((e) => reject(e))
        }
    })
}

export const mutations = {
    SET_VALUE(state, values) {
        Object.keys(values).forEach((value) => {
            state[value] = values[value]
        })
    },
    SET_INTENT(state, value) {
        state.intent = value
    },
}

export const actions = {
    setValue({ commit }, value) {
        commit('SET_VALUE', value)
    },
    async getCards({ commit, dispatch, state }) {
        if (!localStorage.getItem('user.cards')) {
            commit('SET_VALUE', { is_loading: true })
            try {
                const response = await apiClient.get('/account/user/cards')
                commit('SET_VALUE', { cards: response.data })
                localStorage.setItem('user.cards', JSON.stringify(response.data))
            } catch (error) {
                state.vue.$bvToast.toast('There was an error obtaining your cards on file', dangerToast)
            } finally {
                commit('SET_VALUE', { is_loading: false })
            }
        }

        try {
            commit('SET_VALUE', {
                cards: JSON.parse(localStorage.getItem('user.cards')),
            })
        } catch (error) {
            localStorage.removeItem('user.cards')
            dispatch('getCards')
        }
    },
    setDefault({ commit, dispatch, state }, card) {
        commit('SET_VALUE', { is_loading: true })
        return apiClient
            .post('/account/user/card/set-default', {
                method: card,
            })
            .then((response) => {
                localStorage.removeItem('user.cards')
                dispatch('getCards')
                state.vue.$bvToast.toast('Default payment method has been updated successfully.', successToast)
            })
            .catch((error) => {
                state.vue.$bvToast.toast(error.message, dangerToast)
            })
    },
    async obtainIntent({ commit, state }) {
        try {
            const response = await apiClient.get('/card/intent')
            commit('SET_VALUE', { intent: response.data })
        } catch (error) {
            state.vue.$bvToast.toast('Unable to process cards at this time', dangerToast)
        }
    },
    addCard({ commit, state, dispatch }) {
        //if we do not have a setupIntent key do not proceed, this means there is an error on the card and it should be displayed
        if (typeof state.intent_response.setupIntent == 'undefined') return

        return apiClient
            .post('/card', {
                method: state.intent_response.setupIntent.payment_method,
            })
            .then((response) => {
                commit('SET_VALUE', {
                    current_card: state.intent_response.setupIntent.payment_method,
                })
                dispatch('setValue', {
                    intent: null,
                    intent_response: null,
                    show_card_form: null,
                    card: null,
                })
                localStorage.removeItem('user.cards')
                dispatch('getCards')
            })
            .catch((error) => {
                commit('SET_VALUE', { is_loading: false })
                state.vue.$bvToast.toast(error.message, dangerToast)
            })
    },
    removeCard({ commit, state, dispatch }, card) {
        commit('SET_VALUE', { is_loading: true })
        return apiClient
            .delete('account/user/card', {
                data: {
                    method: card,
                },
            })
            .then((response) => {
                localStorage.removeItem('user.cards')
                dispatch('getCards')
                state.vue.$bvToast.toast('The card has been successfully removed', successToast)
            })
            .catch((error) => {
                commit('SET_VALUE', { is_loading: false })
                state.vue.$bvToast.toast(error.message, dangerToast)
            })
    },
    async confirmCardSetup({ commit, state }) {
        const stripe = await loadStripe({ commit, state })
        try {
            const intentResponse = await stripe.confirmCardSetup(state.intent.client_secret, {
                payment_method: {
                    card: state.card,
                },
            })

            if (intentResponse.error) {
                commit('SET_VALUE', { cardError: intentResponse.error.message, paymentMethodComplete: false })
                return
            }
            commit('SET_VALUE', { intent_response: intentResponse })
            return intentResponse
        } catch (error) {
            state.vue.$bvToast.toast(
                'We are currently unable to process cards due to an issue with our card provider.  Please try again soon.',
                dangerToast,
            )
            throw error
        }
    },
    createStripeInput({ commit, state }, elem) {
        //if the card has been created bypass this method
        if (elem.classList.contains('StripeElement')) return
        loadStripe({ commit, state })
            .then((stripe) => {
                let elements = stripe.elements({
                    fonts: [
                        {
                            cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans:400',
                        },
                    ],
                })

                let card = elements.create('card', {
                    style: {
                        base: {
                            color: '#495057',
                            fontFamily: '"Open Sans", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
                            fontSmoothing: 'antialiased',
                            fontSize: '16px',
                            '::placeholder': {
                                color: '#6c757d',
                            },
                        },
                        invalid: {
                            color: '#dc3545',
                            iconColor: '#dc3545',
                        },
                    },
                })
                card.mount(elem)

                // Handle real-time validation errors from the card Element.
                card.addEventListener('change', (event) => {
                    if (state.cards.length && state.current_card != 'NEW') {
                        commit('SET_VALUE', { current_card: 'NEW' })
                    }

                    if (event.error) {
                        commit('SET_VALUE', { cardError: event.error.message, paymentMethodComplete: false })
                    } else {
                        commit('SET_VALUE', { cardError: '' })
                    }

                    if (event.complete) {
                        commit('SET_VALUE', { paymentMethodComplete: true })
                        document.getElementById('submitCard').focus()
                    } else {
                        commit('SET_VALUE', { paymentMethodComplete: false })
                    }
                })

                card.on('ready', (event) => {
                    card.focus()
                })

                commit('SET_VALUE', { card: card })
            })
            .catch((e) => {
                state.vue.$bvToast.toast(
                    'We are currently unable to process cards due to an issue with our card provider.  Please try again soon.',
                    dangerToast,
                )
            })
    },
}

export const getters = {
    defaultCard: (state) => {
        let card = state.cards.find((card) => !!card.default)
        return card?.id
    },
    hasValidCardInfo: (state) => state.paymentMethodComplete && state.cardError.trim().length == 0,
}

export default {
    cards: (state) => state.cards,
}
